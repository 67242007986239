import { mapState } from 'vuex'

import { addressUtilsMixin } from '../../../shared/mixins/addressUtilsMixin'
import { apiMixin } from '../../../shared/mixins/apiMixin'
import { fileUtilsMixin } from '../../../shared/mixins/fileUtilsMixin'
import { getItem } from "@/api/api-methods";
import { validationFormMixin } from '../../../shared/mixins/validationFormMixin'

import KnBackToTopButton from '../../../shared/components/KnBackToTopButton.vue'
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue'
import KnFormSubtitle from '../../../shared/components/KnFormSubtitle.vue'
import KnFormTitle from '../../../shared/components/KnFormTitle.vue'
import KnLocalAlert from '../../../shared/components/KnLocalAlert.vue'
import KnSelect from '../../../shared/components/KnSelect.vue'
import KnTextArea from '../../../shared/components/KnTextArea.vue'
import KnTextField from '../../../shared/components/KnTextField.vue'
import { fetchGetPriceProduct } from '../../../products/helpers/productUtils';
import { fetchGetOrder, fetchPutOrder } from '../../helpers/ordersUtils';
export default {
  components: {
    KnFormSubtitle,
    KnTextField,
    KnSelect,
    KnLocalAlert,
    KnFormActionButtons,
    KnTextArea,
    KnFormTitle,
    KnBackToTopButton
  },
  mixins: [
    addressUtilsMixin,
    apiMixin,
    fileUtilsMixin,
    validationFormMixin,
  ],
  data() {
    return {
      tabs: [
        { name: 'Detalle de la orden', value: 100 },
      ],
      valueDeterminate: 100,
      routerName: 'Ordenes',
      orderId: null,
      currentPersonalData: null,
      currentAddress: null,
      currentOrder: null,
      lastStatus: null,
      products: [],
      orderStatus: [],
      students: [],
      shippingTypes: [],
      measurementUnits: [],
      coins: [],
      items: [],
      headers: [
        { text: 'Producto', value: 'producto.nombre_producto', class: 'purple--text' },
        { text: 'Peso volumétrico', value: 'producto.peso_volumetrico', class: 'purple--text' },
        { text: 'Cantidad', value: 'unidades', class: 'purple--text' },
        { text: 'Unidad de medida', value: 'unidad_medida.dato', class: 'purple--text' },
        { text: 'Precio unitario', value: 'precio_unitario', class: 'purple--text' },
        { text: 'Sub total', value: 'sub_total', class: 'purple--text' },
        { text: 'Impuestos', value: 'total_impuesto', class: 'purple--text' },
        { text: 'Total', value: 'total', class: 'purple--text' },
      ],

      showOrderSummary: false,
      showProductsTable: false,
      error: null,
      loadingPage: false,

      /** Variables para alerta */
      errors: [],
      warnings: [],
      loading: false,
      showAlert: false,
      alertType: 'success',
      alertText: 'Registro exitoso',
      alertColor: null,
      /************************ */
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    title() {
      return this.currentOrder === null ? 'Agregar orden' : 'Detalle de la orden'
    },
    isEditMode() {
      return this.currentOrder !== null
    },
    successAlertText() {
      return this.isEditMode ? 'Estatus actualizado con éxito!' : 'Orden registrada con éxito!'
    },
    successAlertType() {
      return this.isEditMode ? 'info' : 'success'
    },
    enableBtnAccept() {
      return this.lastStatus !== this.currentOrder.estatus_orden.id
    },
  },
  async created() {
    this.loadingPage = true
    this.setLoadingState(true, 'Por favor, espere. Cargando...', 'info');
    try {
      this.orderId = this.validateOrderId(this.$route.params.id);
      await this.constructorOrder()
      await this.loadDataOrderDetail()
    } catch (error) {
      console.error('Error in created:', error);
      this.error = "Error al cargar datos. ";
      this.setErrorState('Error al cargar datos. Por favor, inténtelo de nuevo.')
    } finally {
      this.loadingPage = false
      this.setLoadingState(false)
    }
  },

  methods: {
    //#region alert methods
    setLoadingState(loading, alertText = '', alertType = 'info') {
      this.loading = loading;
      this.alertText = alertText;
      this.showAlert = loading;
      this.alertType = alertType;
    },

    setErrorState(alertText) {
      this.errors.push(alertText); 
      this.loading = false; 
      this.alertText = alertText;
      this.alertType = 'error';
      this.showAlert = true;
    },

    setSuccessState(alertText) {
      this.loading = false; 
      this.alertText = alertText || this.successAlertText;
      this.alertType = this.successAlertType;
      this.alertColor = 'success';
      this.showAlert = true;
    },

    setWarningState(warningText) {
      this.warnings.push(warningText);
      this.alertText = warningText;
      this.alertType = 'warning';
      this.alertColor = 'warning';
      this.showAlert = true;
    },
    //#endregion

    validateOrderId(orderId) {
      if (!/^\d+$/.test(orderId)) {
        this.error = "Formato de ID no válido.";
        throw new Error('Formato de ID no válido');
      }
      return orderId;
    },

    setTabValue(val) {
      this.valueDeterminate = val
    },

    async loadDataOrderDetail() {
      const [
        orderStatusRes,
        productsRes,
      ] = await Promise.all([
        getItem(`/app-ordenes/filters/mv-estatus-orden?estatus_sistema=true&limit=100`),
        getItem(`/app-ordenes/filters/detalle-orden?orden=${this.orderId}&institucion_educativa=${this.institutionId}&estatus_sistema=true`),
      ]);

      this.orderStatus = orderStatusRes.results;
      this.products = productsRes.results;

    },
    async updateOrderStatus() {
      this.setLoadingState(true, 'Actualizando estatus de orden', 'info');
      try {
        const { res } = await fetchPutOrder(this.currentOrder);
        if (res.e) this.setErrorState('No se pudo editar el estatus de la orden')
        else this.setSuccessState('Estatus de la orden actualizado exitosamente')
      } catch (error) {
        console.error('Error al intentar actualizar el estatus de la orden', error);
        this.setErrorState('Error al actualizar el estatus de la orden');
      }
    },
    cancel() {
      this.returnToTable()
    },
    actionAlertBtn1() {
      if (this.alertType === 'success' || this.alertType === 'info') {
        this.returnToTable()
      } else {
        this.closeAlert()
      }
    },
    continueAdding() {
      this.clean()
      this.closeAlert()
    },
    returnToTable() {
      this.$router.replace({ name: this.routerName })
    },
    closeAlert() {
      this.errors = []
      this.showAlert = false
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;

    },
    findProductPrice() {
      const product = this.products.find(p => p.id === this.productId)
      const productPrice = product.precios.find(pr => pr.distribuidor === this.institutionId)
      this.distributionPrice = productPrice.precio_distribuidor
      this.inventory.moneda = productPrice.moneda
    },
    formatCurrency(amount) {
      return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(amount);
    },

    async constructorOrder() {
      try {
        const { res } = await fetchGetOrder(this.orderId)
        this.currentOrder = res
        this.lastStatus = res.estatus_orden.id
        await this.loadOrderDetail(res.id, res.direccion_envio.id, res.alumno.datos_personales)
      } catch (error) {
        this.error = "Error al obtener informacion del la orden ";
        console.error('Error in fillData:', error);
      }
    },

    async loadOrderDetail(orderId, addressId, personalDataId) {
      try {
        const [address, details, personalData] = await Promise.all([
          getItem(`/app-personas/direccion/${addressId}`),
          getItem(`/app-ordenes/filters/detalle-orden?orden=${orderId}&autor=${this.userData.id}&institucion_educativa=${this.institutionId}&estatus_sistema=true`),
          getItem(`/app-personas/datos-personales/${personalDataId}`),
        ]);

        const fullAddress = this.fullAddressWithZipObj(address);

        const items = await Promise.all(details.results.map(async (detail) => {
          const unitPriceRes = await fetchGetPriceProduct(detail.producto.precios[0]);
          const unitPrice = parseFloat(unitPriceRes.precio_distribuidor) || 0;
          const subtotalAmount = parseFloat(detail.sub_total) || 0;
          const taxAmount = parseFloat(detail.impuesto) || 0;
          const totalPrice = subtotalAmount + taxAmount;

          return {
            ...detail,
            total_impuesto: this.formatCurrency(taxAmount),
            total: this.formatCurrency(totalPrice),
            precio_unitario: this.formatCurrency(unitPrice),
            sub_total: this.formatCurrency(subtotalAmount),
          };
        }));

        this.items = items;
        this.currentPersonalData = personalData;
        this.currentAddress = fullAddress;
      } catch (error) {
        this.error = "Error al cargar los detalles de la orden";
        console.error('Error in loadOrderDetail:', error);
      }
    }
  }
}